import React, { useCallback, useEffect, useState, useContext } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import Dashboard from './components/Dashboard/Dashboard'
import Users from './components/pages/Users/Users'
import Materials from './components/pages/Materials/Materials'
import Material from './components/pages/Material/Material'
import Error404Page from './components/pages/Error404Page/Error404Page'
import Locations from './components/pages/Locations/Locations'
import LoginPage from './components/LoginPage/LoginPage'
import Logout from './components/pages/Logout/Logout'
import QuickAssemble from './components/pages/QuickAssemble/QuickAssemble'
import InventoryLog from './components/pages/InventoryLog/InventoryLog'
import UploadImage from './components/UploadImage/UploadImage'
import UserContext from './components/Context/UserContext'
import Homepage from './components/Homepage'
import LoadingMessage from './components/layout/LoadingMessage/LoadingMessage'
import './styles/defaultTheme.css'
import './styles/base.css'
import Vendors from './components/pages/Vendors/Vendors'
import Customers from './components/pages/Customers/Customers'
import EditJob from './components/pages/EditJob/EditJob'


export const UserProvider = ({ children }) => {
	const [user, setUser] = useState({ loaded: false, ok: false })

	const loadUser = useCallback(async () => {
		try {
			const response = await fetch('/api/auth/me')
			if (!response.ok) {
				throw new Error('Failed to authenticate user')
			}
			const data = await response.json()
			setUser({ loaded: true, ok: true, ...data })
			return { loaded: true, ok: true, ...data }
		} catch (error) {
			setUser({ loaded: true, ok: false })
			return { loaded: true, ok: false }
		}
	}, [])

	useEffect(() => {
		if (!user.loaded) {
			loadUser()
		}
	}, [user, loadUser])

	return <UserContext.Provider value={{ user, loadUser }}>{children}</UserContext.Provider>
}

const App = () => (
	<UserProvider>
		<BrowserRouter basename='/'>
			<Routes>
				<Route path='/' element={<LoginPage />} />
				<Route path='/dashboard/*' element={<Dashboard />}>
					<Route
						path='*'
						element={
							<AuthWrapper redirectPath='/'>
								<DashboardLayout />
							</AuthWrapper>
						}
					/>
				</Route>
				<Route path='*' element={<Dashboard />}>
					<Route
						path='*'
						element={
							<AuthWrapper redirectPath='/'><Error404Page /></AuthWrapper>
						}
					/>
				</Route>
			</Routes>
		</BrowserRouter>
	</UserProvider>
)

const AuthWrapper = ({ children, redirectPath }) => {
	const { user } = useContext(UserContext)

	if (!user.loaded) {
		return <LoadingMessage message={'Checking auth...'} />
	}

	return user.ok ? children : <Navigate to={redirectPath} />
}

const DashboardLayout = () => (
	<Routes>
		<Route path='' element={<Homepage />} />
		<Route path='users' element={<Users />} />
		<Route path='vendors' element={<Vendors />} />
		<Route path='customers' element={<Customers />} />
		<Route path='locations' element={<Locations />} />
		<Route path='materials' element={<Materials />} />
		<Route path='material/:materialId' element={<Material />} />
		<Route path='edit-job' element={<EditJob />} />
		<Route path='quick-assemble/:materialId' element={<QuickAssemble />} />
		<Route path='inventory-log/:materialId' element={<InventoryLog />} />
		<Route path='image/upload' element={<UploadImage />} />
		<Route path='logout' element={<Logout />} />
		<Route path='*' element={<Error404Page />} />
	</Routes>
)

export default App
