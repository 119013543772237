import PageTitle from "../../layout/PageTitle"
import { AgGridReact } from "ag-grid-react" // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css" // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css" // Optional Theme applied to the Data Grid
import { useMemo, useState, useCallback } from "react"
import PageContent from "../../layout/PageContent"
import useFetch from "../../../hooks/useFetch"
import LoadingMessage from "../../layout/LoadingMessage/LoadingMessage"
import ErrorMessage from "../../layout/ErrorMessage/ErrorMessage"
import { useNavigate, useParams } from "react-router-dom"
import { formatDate } from "../../../utils/format"

const InventoryLog = () => {
	const params = useParams()
	const materialId = params.materialId

	const navigate = useNavigate()

	const {
		error,
		isPending,
		data: inventoryLog,
		reloadData,
	} = useFetch("/api/inventory/log", {
		material_id: materialId,
	})

	return (
		<div id='Locations'>
			<PageTitle>Inventory Log</PageTitle>
			<PageContent>
				<button onClick={() => navigate(`/dashboard/material/${materialId}`)}>Back</button>
				{error && <ErrorMessage message={error} />}
				{isPending && <LoadingMessage />}
				{inventoryLog && <InventoryLogTable data={inventoryLog} />}
			</PageContent>
		</div>
	)
}

const InventoryLogTable = ({ data }) => {
	// Row Data: The data to be displayed.
	const rowData = data
	const [gridApi, setGridApi] = useState(null)

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	// Apply settings across all columns
	const defaultColDef = useMemo(() => ({
		filter: true,
	}))


	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{ headerName: "Date / Time", field: "change_date", cellRenderer: ({ value }) => formatDate(value)},
		{ headerName: "Location ID", field: "location_id" },
		{ headerName: "QTY Change", field: "quantity_change" },
		{ headerName: "New QTY", field: "new_quantity" },
		{ headerName: "User ID", field: "changed_by" },
		{ headerName: "Change Type", field: "change_type" },
		{ headerName: "Reason", field: "reason" },
	])

	return (
		<div className='ag-theme-quartz' style={{ height: 400 }}>
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				pagination={true}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
			/>
		</div>
	)
}

export default InventoryLog
