import { useState } from 'react'
import Collapsible from '../../layout/Collapsible/Collapsible'
import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import MaterialQtySelector from '../../MaterialQtySelector/MaterialQtySelector'

import './EditJob.css'

const EditJob = () => {

    const [selectedMaterials, setSelectedMaterials] = useState([{material_id: 1, name: "test", quantity: 1}])

    return (
        <div id="EditJob">
            <PageTitle>Edit Job</PageTitle>
            <PageContent>
                <Collapsible title="Job Details">
                    <label>
                        Job ID
                        <input type='number' disabled/>
                    </label>
                    <label>
                        Date Created
                        <input type='date' disabled/>
                    </label>
                    <label>
                        Customer
                        <input type='text'/>
                    </label>
                    <label>
                        PO Date
                        <input type='date'/>
                    </label>
                    <label>
                        PO Number
                        <input type='text'/>
                    </label>
                    <label>
                        Due Date
                        <input type='date'/>
                    </label>
                    <label>
                        Ship Date
                        <input type='date'/>
                    </label>
                    <label>
                        Ship via
                        <input type='text'/>
                    </label>
                    <label>
                        Tracking Number
                        <input type='text'/>
                    </label>
                </Collapsible>
                <Collapsible title="Materials">
                 <MaterialQtySelector selectedMaterials={selectedMaterials} setSelectedMaterials={setSelectedMaterials}/>
                </Collapsible>
            </PageContent>
        </div>
    )
}

export default EditJob