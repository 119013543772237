
import './MaterialQtySelector.css'

const MaterialQtySelector = ({selectedMaterials = [], setSelectedMaterials}) => {
    return (
        <div>
            {selectedMaterials.map((e, i) => <SelectorRow material={e}/>)}
        </div>
    )

}

const SelectorRow = ({material}) => {
    return (
        <p>{material.material_id} - {material.name} - {material.quantity}</p>
    )
}

export default MaterialQtySelector